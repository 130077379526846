<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.MAIN_ASSETS.TRANSPORT.TYPES') }}
            </h3>
          </div>
          <div class="card-button">
            <v-btn @click="dialog = true" small color="primary">
              {{ $t('MENU.MAIN_ASSETS.TRANSPORT.TYPES') }} +
            </v-btn>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Yangi Mashina Turi</span>
                </v-card-title>
                <v-card-text>
                  <div class="d-flex flex-wrap">
                    <div class="col-6 pl-0">
                      <v-autocomplete
                        v-model="brand"
                        :items="allBrands"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        label="Brand"
                        :error-messages="brandErrors"
                        @input="$v.brand.$touch()"
                        @blur="$v.brand.$touch()"
                      ></v-autocomplete>
                    </div>

                    <div class="col-6 pr-0">
                      <v-text-field
                        dense
                        outlined
                        label="Modeli"
                        v-model="model"
                        :error-messages="modelErrors"
                        @input="$v.model.$touch()"
                        @blur="$v.model.$touch()"
                      ></v-text-field>
                    </div>

                    <div class="col-6 pl-0">
                      <v-text-field
                        dense
                        outlined
                        label="Sig'imi(kg)"
                        v-model="capacity_kg"
                        :error-messages="capacity_kgErrors"
                        @input="$v.capacity_kg.$touch()"
                        @blur="$v.capacity_kg.$touch()"
                      ></v-text-field>
                    </div>
                    <div class="col-6 pr-0">
                      <v-text-field
                        dense
                        outlined
                        label="Sig'imi(m3)"
                        v-model="capacity_m3"
                        :error-messages="capacity_m3Errors"
                        @input="$v.capacity_m3.$touch()"
                        @blur="$v.capacity_m3.$touch()"
                      ></v-text-field>
                    </div>
                    <div class="col-6 pl-0">
                      <v-text-field
                        dense
                        outlined
                        v-currency="options"
                        label="Asosiy haydovchi uchun toʻlov"
                        v-model="price_per_km"
                        :error-messages="mainDriverError"
                        @input="$v.price_per_km.$touch()"
                        @blur="$v.price_per_km.$touch()"
                      ></v-text-field>
                    </div>
                    <div class="col-6 pr-0">
                      <v-text-field
                        dense
                        outlined
                        label="Yordamchi uchun toʻlov"
                        v-model="price_per_km_for_assistant"
                        v-currency="options"
                        :error-messages="mainDriverHelperError"
                        @input="$v.price_per_km_for_assistant.$touch()"
                        @blur="$v.price_per_km_for_assistant.$touch()"
                      ></v-text-field>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="newTrucktypeLoading"
                    color="error"
                    @click="cancel()"
                    >Bekor qilish</v-btn
                  >
                  <v-btn
                    :disabled="newTrucktypeLoading"
                    color="success"
                    @click="save"
                  >
                    <i v-if="newTrucktypeLoading" class="el-icon-loading"></i>
                    Saqlash</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="allTruckTypes"
            :items-per-page="10"
            :page.sync="page"
            class="row-pointer"
            single-select
            @click:row="rowClick"
            item-key="id"
            hide-default-footer
            no-data-text="Malumot kiritilmagan"
          >
            <template v-slot:[`item.index`]="{ item }">
              {{
                allTruckTypes
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1
              }}
            </template>

            <template v-slot:[`item.price_per_km`]="{ item }">
              {{ item.price_per_km.toLocaleString('zh-ZH') }}
            </template>

            <template v-slot:[`item.price_per_km_for_assistant`]="{ item }">
              {{ item.price_per_km_for_assistant.toLocaleString('zh-ZH') }}
            </template>
            <template v-slot:[`item.action`]="{ item }"
              ><action :index="item" />
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(allTruckTypes.length / 10)"
            ></v-pagination>
          </div>

          <!-- <b-table
            striped
            hover
            id="my-table"
            :filter="filterName"
            :fields="fields"
            :items="allTruckTypes"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
          </b-table> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
export default {
  data() {
    return {
      newTrucktypeLoading: false,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      locale: 'default',
      distractionFree: false,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      allowNegative: false,
      model: '',
      price_per_km_for_assistant: '',
      page: 1,
      pageCount: 0,
      brand: '',
      price_per_km: null,
      capacity_m3: '',
      capacity_kg: '',
      dialog: false,
      perPage: 10,
      currentPage: 1
    }
  },
  validations: {
    brand: {
      required
    },
    model: {
      required
    },
    price_per_km: {
      required
    },
    price_per_km_for_assistant: {
      required
    },
    capacity_m3: {
      required
    },
    capacity_kg: {
      required
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllTruckTypes')
    this.$store.dispatch('getAllBrands')
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.MAIN_ASSETS.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.TITLE') },
      { title: this.$t('MENU.MAIN_ASSETS.TRANSPORT.TYPES') }
    ])
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'brand.name'
        },
        {
          text: this.$t('TABLE_HEADER.MODEL'),
          value: 'model'
        },
        {
          text: 'Kg',
          value: 'capacity_kg'
        },
        {
          text: 'm3',
          value: 'capacity_m3'
        },
        {
          text: this.$t('TABLE_HEADER.PRICE_PER_KM_MAINDRIVER'),
          value: 'price_per_km'
        },
        {
          text: this.$t('TABLE_HEADER.PRICE_PER_KM_ASSISTANT'),
          value: 'price_per_km_for_assistant'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [null],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    capacity_kgErrors() {
      const errors = []
      if (!this.$v.capacity_kg.$dirty) return errors
      !this.$v.capacity_kg.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    capacity_m3Errors() {
      const errors = []
      if (!this.$v.capacity_m3.$dirty) return errors
      !this.$v.capacity_m3.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    modelErrors() {
      const errors = []
      if (!this.$v.model.$dirty) return errors
      !this.$v.model.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainDriverError() {
      const errors = []
      if (!this.$v.price_per_km.$dirty) return errors
      !this.$v.price_per_km.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainDriverHelperError() {
      const errors = []
      if (!this.$v.price_per_km_for_assistant.$dirty) return errors
      !this.$v.price_per_km_for_assistant.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    brandErrors() {
      const errors = []
      if (!this.$v.brand.$dirty) return errors
      !this.$v.brand.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allTruckTypes() {
      const data = this.$store.state.requests.allTruckTypes
      return data.reverse()
    },
    allBrands() {
      const data = this.$store.state.requests.allBrands
      return data
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    cancel() {
      this.dialog = false
      this.$v.$reset()
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          brand: this.brand,
          model: this.model,
          price_per_km_for_assistant: parseInt(
            this.price_per_km_for_assistant.split(',').join(''),
            10
          ),
          capacity_kg: this.capacity_kg,
          capacity_m3: this.capacity_m3,
          price_per_km: parseInt(this.price_per_km.split(',').join(''), 10)
        }
        // console.log(data);
        this.newTrucktypeLoading = true
        this.$store
          .dispatch('CreateTruckTypes', data)
          .then(() => {
            this.newTrucktypeLoading = false
            this.dialog = false
            this.brand = ''
            this.model = ''
            this.capacity_kg = ''
            this.capacity_m3 = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newTrucktypeLoading = false
            console.error(err)
          })
      }
    }
  },
  components: {
    action
  }
}
</script>
