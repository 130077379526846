<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" dark v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline col">{{ $t('ACTION.UPDATE') }}</span>
        </v-card-title>
        <v-card-text>
          <div class="inputs__wrapper">
            <div class="col-6" v-if="data.brand !== undefined">
              <v-autocomplete
                :items="allBrands"
                outlined
                v-model="data.brand.id"
                item-text="name"
                item-value="id"
                dense
                label="Brand Nomi"
              ></v-autocomplete>
            </div>
            <div class="col-6">
              <v-text-field
                dense
                outlined
                v-model="data.model"
                label="Model"
              ></v-text-field>
            </div>
            <div class="col-6">
              <v-text-field
                dense
                outlined
                v-model="data.capacity_kg"
                label="Kg"
              ></v-text-field>
            </div>
            <div class="col-6">
              <v-text-field
                dense
                outlined
                v-model="data.capacity_m3"
                label="M3"
              ></v-text-field>
            </div>
            <div class="col-6">
              <v-text-field
                dense
                outlined
                v-model="data.price_per_km"
                label="Asosiy haydovchi uchun km"
              ></v-text-field>
            </div>
            <div class="col-6">
              <v-text-field
                dense
                outlined
                v-model="data.price_per_km_for_assistant"
                label="Yordamchi uchun km"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false">Yopish</v-btn>
          <v-btn color="success" @click="submitUpdate">Saqlash</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      brandName: '',
      data: {}
    }
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.actionObj))
  },
  methods: {
    submitUpdate() {
      const data = {
        brand: this.data.brand.id,
        model: this.data.model,
        price_per_km_for_assistant: this.data.price_per_km_for_assistant,
        capacity_kg: this.data.capacity_kg,
        capacity_m3: this.data.capacity_m3,
        price_per_km: this.data.price_per_km
      }
      console.log(data)
      this.dialog = false
      this.$store.dispatch('newupdateTruck', { id: this.data.id, data })
    }
  },
  computed: {
    allBrands() {
      return this.$store.state.requests.allBrands
    }
  },
  props: {
    actionObj: Object
  }
}
</script>

<style scoped>
.inputs__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
